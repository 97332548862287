.header {
    color: var(--text-dark);
    margin: 1em 2em;
    display: flex;
    justify-content: center;
    position: relative;

    &__logo {
        display: none;
        flex-grow: 0;
        width: 3.2em;
        margin-right: 1em;

        &:hover {
            -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
        }

        @media (min-width: 500px) {
            display: block;
            -webkit-transition: -webkit-transform 500ms ease-in-out;
            transition: -webkit-transform 500ms ease-in-out;
            transition: transform 500ms ease-in-out;
            transition: transform 500ms ease-in-out,
                -webkit-transform 500ms ease-in-out;
        }
    }

    &__title {
        flex-grow: 1;
        font-size: 2.2rem;
        font-weight: 300;
        font-family: var(--font-operetta);

        @media (min-width: 400px) {
            font-size: 2.6rem;
        }

        @media (min-width: 500px) {
            font-size: 3rem;
        }
    }

    @media (min-width: 900px) {
        width: 90%;
        max-width: 1200px;
        margin: 2em auto;
        justify-content: space-around;

        &__logo,
        &__title {
            flex-grow: 0;
        }
    }
}
