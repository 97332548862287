@charset "UTF-8";
@import url("https://use.typekit.net/vzv1rny.css");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
:root {
  --clr-primary: #889b9d;
  --clr-primary-dark: #889b9d;
  --clr-accent: #deacae;
}
.header {
  color: var(--text-dark);
  margin: 1em 2em;
  display: flex;
  justify-content: center;
  position: relative;
}
.header__logo {
  display: none;
  flex-grow: 0;
  width: 3.2em;
  margin-right: 1em;
}
.header__logo:hover {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
@media (min-width: 500px) {
  .header__logo {
    display: block;
    -webkit-transition: -webkit-transform 500ms ease-in-out;
    transition: -webkit-transform 500ms ease-in-out;
    transition: transform 500ms ease-in-out;
    transition: transform 500ms ease-in-out, -webkit-transform 500ms ease-in-out;
  }
}
.header__title {
  flex-grow: 1;
  font-size: 2.2rem;
  font-weight: 300;
  font-family: var(--font-operetta);
}
@media (min-width: 400px) {
  .header__title {
    font-size: 2.6rem;
  }
}
@media (min-width: 500px) {
  .header__title {
    font-size: 3rem;
  }
}
@media (min-width: 900px) {
  .header {
    width: 90%;
    max-width: 1200px;
    margin: 2em auto;
    justify-content: space-around;
  }
  .header__logo, .header__title {
    flex-grow: 0;
  }
}
.navigation {
  align-items: center;
}
.navigation__list {
  display: none;
  top: 2.4em;
  right: -1em;
  list-style: none;
  align-self: center;
  text-align: right;
  position: absolute;
  font-size: 1.4rem;
  font-weight: 400;
  width: 8em;
  border-radius: 4px;
  background-color: var(--primary-color-bright);
  padding: 0.2em 0.8em 0.8em 0.8em;
  z-index: 999;
}
@media (min-width: 400px) {
  .navigation__list {
    top: 2.6em;
  }
}
.navigation__item {
  display: block;
  position: relative;
  text-align: right;
  padding: 0.1em 0.25em;
  color: var(--primary-color-dark);
  text-decoration: none;
}
.navigation__item-active {
  font-weight: 700;
  color: var(--text-grey);
}
.navigation__item:hover {
  cursor: pointer;
  color: var(--primary-color);
}
.navigation__sub-menu__list {
  display: none;
  list-style: none;
  font-size: 1.1rem;
  line-height: 1.2;
}
.navigation__sub-menu__toggle {
  display: none;
}
.navigation__sub-menu__toggle:checked ~ .navigation__sub-menu {
  color: var(--text-dark);
}
.navigation__sub-menu__toggle:checked ~ .navigation__sub-menu__list {
  display: block;
}
@media (min-width: 900px) {
  .navigation {
    flex-grow: 1;
    display: flex;
  }
  .navigation__toggle:checked ~ .navigation__sub-menu {
    color: var(--primary-color);
  }
  .navigation__toggle:checked ~ .navigation__sub-menu__list {
    display: none;
  }
  .navigation__list {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    position: static;
    padding: 0;
    width: 100%;
    background-color: white;
  }
  .navigation__list li {
    display: inline-block;
  }
  .navigation__item {
    font-size: 1.4rem;
    margin-left: 0.8em;
    position: relative;
    line-height: 1.6;
    padding: 0;
  }
  .navigation__item:hover ~ .navigation__item__list {
    color: var(--primary-color) !important;
  }
  .navigation__sub-menu {
    position: relative;
  }
  .navigation__sub-menu__list {
    margin-left: 0;
    top: 2em;
    left: 0;
    width: 190%;
    padding: 1em;
    position: absolute;
    border-radius: 4px;
    background-color: var(--primary-color-bright);
  }
  .navigation__sub-menu__list li {
    display: block;
    text-align: left;
  }
  .navigation__sub-menu__item {
    color: var(--text-dark);
    font-size: 1.1rem;
    margin: 0 0 0.2em 0;
    text-align: left;
  }
  .navigation__sub-menu:hover .navigation__sub-menu__list {
    display: block;
  }
}
.hamburger-menu-toggle {
  display: none;
}
.hamburger-menu-toggle:checked ~ .navigation__list {
  display: block;
}
.hamburger-menu:hover {
  cursor: pointer;
}
.hamburger-menu__stroke {
  position: relative;
}
.hamburger-menu__stroke, .hamburger-menu__stroke::before, .hamburger-menu__stroke::after {
  width: 2em;
  height: 3px;
  background-color: var(--primary-color);
  border-radius: 3px;
}
.hamburger-menu__stroke::before {
  content: "";
  position: absolute;
  bottom: 0.5em;
}
.hamburger-menu__stroke::after {
  content: "";
  position: absolute;
  top: 0.5em;
}
.hamburger-menu {
  display: flex;
  align-items: center;
  position: relative;
  width: 2em;
}
@media (min-width: 900px) {
  .hamburger-menu {
    display: none;
  }
}
.hamburger-menu__toggle-label {
  position: relative;
}
.hamburger-menu__toggle-label, .hamburger-menu__toggle-label::before, .hamburger-menu__toggle-label::after {
  width: 2em;
  height: 2px;
  background-color: var(--text-dark);
  border-radius: 2px;
}
.hamburger-menu__toggle-label::before, .hamburger-menu__toggle-label::after {
  content: "";
  position: absolute;
}
.hamburger-menu__toggle-label::before {
  bottom: 0.5em;
}
.hamburger-menu__toggle-label::after {
  top: 0.5em;
}
.footer {
  background-color: var(--primary-color-darkest);
  color: var(--text-white);
}
.footer__content {
  padding: 1.6em 2em 4em;
  flex-grow: 1;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  position: relative;
  align-items: flex-start;
}
@media (min-width: 900px) {
  .footer__content {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
  }
}
.footer__veb-logo {
  display: none;
}
.footer__left {
  flex-grow: 1;
}
.footer__right {
  align-self: flex-end;
  text-align: right;
}
.footer__title {
  font-family: var(--font-operetta);
  font-size: 2em;
  font-weight: 400;
  margin-bottom: -0.5em;
}
.footer__address {
  display: none;
}
.footer__disclaimer {
  margin-top: 0.4em;
  display: block;
}
.footer__disclaimer * {
  color: rgba(255, 255, 255, 0.4);
  font-size: 0.7rem;
}
.footer__disclaimer ul {
  list-style: none;
}
.footer__disclaimer li {
  display: inline;
}
.footer__disclaimer a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.4);
}
.footer__disclaimer a:hover {
  color: rgba(255, 255, 255, 0.8);
}
.footer__email, .footer__phone {
  display: none;
}
.footer__name {
  font-size: 0.9em;
  font-weight: 200;
  color: rgba(255, 255, 255, 0.6);
  display: none;
}
.footer__icon {
  display: inline;
  margin-top: 0.4em;
  margin-left: 0.15em;
  margin-bottom: 3em;
  text-decoration: none;
  line-height: 0.8;
  color: rgba(255, 255, 255, 0.6);
}
.footer__icon i {
  font-size: 1.6em;
}
.footer__icon:hover {
  color: var(--text-white);
}
@media (min-width: 700px) {
  .footer__veb-logo {
    opacity: 0.7;
    display: block;
    align-self: center;
    width: 4.2em;
    margin-right: 1.4em;
  }
  .footer__veb-logo:hover {
    opacity: 0.8;
  }
  .footer__title {
    color: var(--text-white);
    line-height: 1.2;
    margin: 0;
  }
  .footer__address {
    display: block;
    font-weight: 200;
    line-height: 1.4;
  }
  .footer__right * {
    line-height: 1.8;
  }
  .footer__email {
    display: block;
    color: var(--text-white);
    unicode-bidi: bidi-override;
    text-align: left;
    direction: rtl;
    font-weight: 200;
  }
  .footer__email i {
    font-size: 0.9em;
    margin-right: 0.5em;
    opacity: 0.6;
  }
  .footer__phone {
    display: block;
    color: var(--text-white);
    text-align: right;
    font-weight: 200;
    line-height: 1.4;
  }
  .footer__phone i {
    font-size: 0.9em;
    margin-right: 0.5em;
    opacity: 0.6;
  }
  .footer__disclaimer {
    position: absolute;
    bottom: 1em;
    right: 2em;
  }
  .footer__disclaimer li {
    margin: 0 0.2em;
  }
}
:root {
  --text-dark: #333;
  --text-grey: #555;
  --text-light-grey: #777;
  --text-white: #eee;
  --primary-color: #889b9d;
  --primary-color-bright: #f2f3f3;
  --primary-color-dark: #5e6566;
  --primary-color-darkest: #3d4242;
  --accent-color: #deacae;
  --font-operetta: "operetta-32";
  --font-roboto: "Roboto";
}
*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
html {
  scroll-behavior: smooth;
  height: 100vh;
}
body {
  height: 100vh;
  font-family: var(--font-roboto), sans-serif;
  font-weight: 200;
  line-height: 1.6;
  display: flex;
  flex-direction: column;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.content section {
  width: 100%;
  padding: 0;
}
hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), var(--primary-color), rgba(0, 0, 0, 0));
}
.hero {
  display: flex;
  flex-direction: column;
  padding: 0 2em;
}
.hero-cta {
  display: flex;
  flex-direction: column;
  margin: 2em auto;
  text-align: center;
}
.hero-card {
  display: none;
  position: relative;
  max-width: 540px;
  margin-left: auto;
  margin-right: auto;
  animation-name: fade;
  animation-duration: 0.5s;
}
.hero-card--active {
  display: block;
}
@media (min-width: 1000px) {
  .hero-card {
    max-width: 800px;
  }
}
.hero-image {
  text-align: center;
  border-radius: 0.5em;
  max-width: 100%;
  object-fit: contain;
  animation-name: fade;
  animation-duration: 1.5s;
  z-index: -10;
}
.hero-card-text {
  font-weight: 900;
  font-size: 2rem;
  line-height: 1.3em;
  color: white;
  text-shadow: 0 0 2px var(--text-grey);
  position: absolute;
  width: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}
.paginator {
  line-height: 0.6;
}
.hero-carousel-indicator {
  line-height: 0.6;
  font-size: 3rem;
  color: var(--text-dark);
  opacity: 0.25;
}
.hero-carousel-indicator--active {
  opacity: 1;
}
@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
.cta {
  margin: 1em auto;
  padding: 1em 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
@media (min-width: 420px) {
  .cta {
    flex-direction: row;
    margin-top: 2em;
    justify-content: center;
    max-width: 800px;
  }
}
.cta__button {
  text-decoration: none;
  text-transform: uppercase;
  font-family: var(--font-roboto);
  font-weight: bold;
  font-size: 1.1rem;
  padding: 0.25em 0.5em;
  color: white;
  border: none;
  border-radius: 1em;
  margin: 0.25em auto;
  border: 1px solid var(--accent-color);
}
@media (min-width: 420px) {
  .cta__button {
    font-size: 1.1rem;
    margin: 0.25em 0.75em;
  }
}
.cta__button-primary {
  background-color: var(--accent-color);
}
.cta__button-secondary {
  background-color: white;
  color: var(--accent-color);
}
.cta__button:hover {
  box-shadow: 0px 0px 8px var(--accent-color);
}
.cta__link {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 700;
}
.cta__link:hover {
  color: var(--accent-color);
}
.key-data {
  align-self: center;
  color: var(--text-dark);
  display: flex;
  flex-flow: column wrap;
  justify-content: space-evenly;
}
.key-data__item {
  flex-grow: 1;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  font-size: 1.1rem;
  font-weight: 300;
  align-items: center;
  color: var(--text-grey);
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 1em;
}
@media (min-width: 1000px) {
  .key-data__item {
    max-width: 350px;
    margin-top: 1em;
    align-items: center;
  }
}
.key-data__item h3 {
  font-weight: 500;
  color: black;
  flex-grow: 1;
}
.key-data__item img {
  max-width: 50px;
  margin-right: 1em;
}
@media (min-width: 1000px) {
  .key-data {
    flex-direction: row;
    max-width: 1000px;
  }
}
#services {
  background-color: var(--primary-color-bright);
  box-shadow: inset 0px 0em 3em white;
}
.services {
  padding: 2em;
}
.services h2 {
  text-align: center;
  font-weight: 400;
  font-size: 2.2rem;
  font-weight: 700;
  color: var(--text-dark);
}
@media (min-width: 600px) {
  .services {
    margin: 0 auto;
    padding: 2em 4em;
    max-width: 1000px;
  }
}
.service-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  padding: 2em;
  margin: 2em 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
}
.service-card__cover {
  flex-grow: 1;
  margin: auto 0;
  max-width: 100%;
  border-radius: 0.5em;
}
.service-card__content {
  flex-grow: 1;
  margin-top: 1.2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.service-card q {
  display: inline-block;
}
.service-card:hover {
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
}
.service-card__title {
  font-size: 1.4em;
  font-weight: 400;
  margin-bottom: 0.4em;
  color: var(--text-dark);
}
.service-card__description {
  font-size: 1.2rem;
  font-weight: 300;
  color: var(--primary-color-dark);
}
.service-card__description .quote__author {
  margin-top: 1em;
  font-style: italic;
  font-size: 0.9rem;
  text-align: left;
}
.service-card__link {
  margin-top: 0.4em;
  color: var(--primary-color);
  align-self: flex-end;
  text-decoration: none;
  font-weight: 500;
  padding: 0 0.5em;
  border-radius: 0.5em;
  position: relative;
}
.service-card__link:hover {
  background-color: var(--primary-color);
  color: white;
}
.service-card:last-child {
  margin-bottom: 0;
}
@media (min-width: 900px) {
  .service-card {
    flex-direction: row;
    padding: 0.5em 1em;
    background-color: var(--primary-color-bright);
    margin: 1em;
    max-width: 1000px;
  }
  .service-card__content {
    margin: 0 0 0 1em;
    padding: 2.2em;
  }
  .service-card__cover {
    max-width: 420px;
  }
  .service-card:nth-child(odd) {
    flex-direction: row-reverse;
  }
  .service-card:nth-child(odd) .service-card__content {
    padding: 2.2em;
    margin: 0 1em 0 0em;
  }
  .service-card .quote__author {
    text-align: left;
  }
}
.page {
  flex-grow: 1;
  padding: 0 2em;
  max-width: 1300px;
  align-self: center;
}
.page * {
  line-height: 1.4;
  font-size: 1.2rem;
  color: var(--primary-color-dark);
}
.page__heading {
  display: flex;
  flex-direction: column;
  padding: 0 1em;
  border-radius: 10px;
  max-width: 1000px;
}
.page__heading__cover {
  flex-grow: 1;
  margin: auto 0;
  max-width: 600px;
  border-radius: 0.5em;
}
.page__heading__title {
  font-size: 1.8rem;
  font-weight: 400;
  margin-bottom: 0.1em;
  color: var(--text-dark);
}
.page__heading__subtitle {
  font-size: 1em;
  font-weight: 300;
  margin-bottom: 0.4em;
  color: var(--text-dark);
}
.page__content {
  max-width: 1240px;
  padding: 0 1em;
}
.page__content p {
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  font-weight: 200;
  margin: 1em 0;
}
.page__services {
  margin-bottom: 2em;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.page__services li {
  border-radius: 0.2em;
  font-weight: 500;
  width: 240px;
  padding: 1em;
  margin: 0.5em;
  background-color: var(--primary-color-bright);
}
.about__hero {
  padding: 1em 2em;
  display: flex;
  flex-flow: column;
}
.about__hero__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--text-grey);
  margin-bottom: 1em;
}
.about__hero__heading {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  margin-bottom: 0.5em;
}
@media (min-width: 460px) {
  .about__hero__heading {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
.about__hero__picture {
  align-self: center;
  width: 100%;
  height: auto;
  border-radius: 0.5em;
}
.about__hero__cta {
  align-self: center;
  justify-self: center;
  text-align: center;
  margin: 1em;
  font-size: 1.2rem;
  font-weight: 300;
  color: var(--text-dark);
}
@media (min-width: 600px) {
  .about__hero__content {
    flex-direction: row;
    justify-content: center;
    margin-bottom: 0;
  }
  .about__hero__heading {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 0;
  }
  .about__hero__picture {
    max-width: 300px;
    margin-left: 2em;
  }
  .about__hero__cta {
    margin: 1em;
  }
}
.about__title {
  font-size: 1.8rem;
  font-weight: 400;
  color: var(--text-dark);
}
.about__subtitle {
  font-size: 1rem;
  font-weight: 300;
  max-width: 24ch;
  line-height: 1.2;
  color: var(--text-grey);
}
.about__text {
  padding: 2rem;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.2rem;
  color: var(--text-grey);
}
.about__text p {
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  font-weight: 300;
  margin: 1em 0;
}
.about__text p:first-of-type {
  margin-top: 0;
}
.about__text p:last-of-type {
  margin-bottom: 0;
}
@media (min-width: 600px) {
  .about__title {
    margin-top: 1em;
    font-size: 1.7rem;
  }
  .about__subtitle {
    font-size: 1.1rem;
    font-weight: 300;
  }
}
@media (min-width: 800px) {
  .about__title {
    font-size: 2.6rem;
  }
  .about__subtitle {
    font-size: 1.2rem;
  }
}
.cv {
  --cv-color: var(--primary-color);
  background-color: var(--primary-color-bright);
  padding: 2rem 2rem;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.cv__title {
  font-size: 1.4rem;
  font-weight: 500;
}
.cv__item {
  display: flex;
}
.cv__item__bullet {
  background-color: var(--cv-color);
  color: var(--text-white);
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  font-weight: 900;
  font-size: 1.1rem;
  border-radius: 50%;
  width: 3.5rem;
  height: 3.5rem;
  position: relative;
}
.cv__item__bullet::before, .cv__item__bullet::after {
  content: "";
  position: absolute;
  width: 3px;
  background-color: var(--cv-color);
}
.cv__item__bullet::before {
  bottom: 100%;
  height: 50%;
}
.cv__item__bullet::after {
  top: 100%;
  height: 65%;
}
.cv__item:hover .cv__item__bullet, .cv__item:focus-within .cv__item__bullet {
  transform: scale(1.1);
}
.cv__item__content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 77%;
  margin-left: 1em;
  min-height: 90px;
  line-height: 1.2;
}
.cv__item__content p {
  margin-top: 0.2em;
}
.cv__item__name {
  font-size: 1.1rem;
  font-weight: 400;
}
.cv__item__description {
  font-size: 1rem;
}
.cv__item:first-of-type .cv__item__bullet::before, .cv__item:last-of-type .cv__item__bullet::after {
  display: none;
}
@media (min-width: 600px) {
  .cv {
    padding-left: 20%;
  }
}
.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-grow: 1;
}
.contact__logo {
  margin-top: 1em;
  width: 240px;
  -webkit-transition: -webkit-transform 500ms ease-in-out;
  transition: -webkit-transform 500ms ease-in-out;
  transition: transform 500ms ease-in-out;
  transition: transform 500ms ease-in-out, -webkit-transform 500ms ease-in-out;
}
.contact__logo:hover {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.contact__heading {
  font-size: 2.4rem;
  font-weight: 400;
  margin-top: 1em;
  color: var(--primary-color-dark);
}
.contact__sub-heading {
  font-size: 1.4rem;
  font-weight: 300;
  margin-bottom: 1em;
}
.contact__content {
  width: 240px;
  margin-bottom: 2em;
  font-weight: 300;
}
.contact__phone {
  display: block;
  margin-top: 0.4em;
  position: relative;
  margin-top: 0.4em;
  margin-left: 1.5em;
  text-decoration: none;
}
.contact__phone::before {
  content: "";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  position: absolute;
  left: -1.5em;
}
.contact__email {
  display: block;
  unicode-bidi: bidi-override;
  text-align: left;
  direction: rtl;
  position: relative;
  margin-top: 0.4em;
  margin-left: 1.5em;
  text-decoration: none;
}
.contact__email::before {
  content: "";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  position: absolute;
  left: -1.5em;
}
.contact__address {
  display: inline-block;
  width: 300px;
  margin-top: 0.4em;
  margin-bottom: 2em;
  margin-left: 1.5em;
  list-style: none;
  position: relative;
}
.contact__address::before {
  content: "";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  left: -1.5em;
}
@media (min-width: 700px) {
  .contact {
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3em;
  }
  .contact__logo {
    width: 240px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .contact__content {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 2em;
  }
}
/*# sourceMappingURL=index.c0f21a74.css.map */
