.navigation {
    align-items: center;

    &__list {
        display: none;
        top: 2.4em;
        right: -1em;
        list-style: none;
        align-self: center;
        text-align: right;
        position: absolute;
        font-size: 1.4rem;
        font-weight: 400;
        width: 8em;
        border-radius: 4px;
        background-color: var(--primary-color-bright);
        padding: 0.2em 0.8em 0.8em 0.8em;
        z-index: 999;

        @media (min-width: 400px) {
            top: 2.6em;
        }
    }

    &__item {
        display: block;
        position: relative;
        text-align: right;
        padding: 0.1em 0.25em;

        color: var(--primary-color-dark);
        text-decoration: none;

        &-active {
            font-weight: 700;
            color: var(--text-grey);
        }

        &:hover {
            cursor: pointer;
            color: var(--primary-color);
        }
    }

    &__sub-menu {
        &__list {
            display: none;
            list-style: none;
            font-size: 1.1rem;
            line-height: 1.2;
        }

        &__toggle {
            display: none;
            &:checked ~ .navigation__sub-menu {
                color: var(--text-dark);
            }
            &:checked ~ .navigation__sub-menu__list {
                display: block;
            }
        }
    }

    @media (min-width: 900px) {
        flex-grow: 1;
        display: flex;

        &__toggle {
            &:checked ~ .navigation__sub-menu {
                color: var(--primary-color);
            }
            &:checked ~ .navigation__sub-menu__list {
                display: none;
            }
        }

        &__list {
            display: flex;
            flex-grow: 1;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            position: static;
            padding: 0;
            width: 100%;
            background-color: white;
            li {
                display: inline-block;
            }
        }

        &__item {
            font-size: 1.4rem;
            margin-left: 0.8em;
            position: relative;
            line-height: 1.6;
            padding: 0;

            &:hover ~ &__list {
                color: var(--primary-color) !important;
            }
        }

        &__sub-menu {
            position: relative;

            &__list {
                margin-left: 0;
                top: 2em;
                left: 0;
                width: 190%;
                padding: 1em;
                position: absolute;
                border-radius: 4px;
                background-color: var(--primary-color-bright);
                li {
                    display: block;
                    text-align: left;
                }
            }

            &__item {
                color: var(--text-dark);
                font-size: 1.1rem;
                margin: 0 0 0.2em 0;
                text-align: left;
            }

            &:hover &__list {
                display: block;
            }
        }
    }
}

.hamburger-menu-toggle {
    display: none;
    &:checked ~ .navigation__list {
        display: block;
    }
}

.hamburger-menu {
    &:hover {
        cursor: pointer;
    }
    &__stroke {
        position: relative;
    }
    &__stroke,
    &__stroke::before,
    &__stroke::after {
        width: 2em;
        height: 3px;
        background-color: var(--primary-color);
        border-radius: 3px;
    }

    &__stroke::before {
        content: '';
        position: absolute;
        bottom: 0.5em;
    }

    &__stroke::after {
        content: '';
        position: absolute;
        top: 0.5em;
    }
}

.hamburger-menu {
    display: flex;
    align-items: center;
    position: relative;
    width: 2em;

    @media (min-width: 900px) {
        display: none;
    }

    &__toggle-label {
        position: relative;
    }

    &__toggle-label,
    &__toggle-label::before,
    &__toggle-label::after {
        width: 2em;
        height: 2px;
        background-color: var(--text-dark);
        border-radius: 2px;
    }

    &__toggle-label::before,
    &__toggle-label::after {
        content: '';
        position: absolute;
    }

    &__toggle-label::before {
        bottom: 0.5em;
    }

    &__toggle-label::after {
        top: 0.5em;
    }
}
