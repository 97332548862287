@use "util/root";
@use "components/header";
@use "components/navigation";
@use "components/footer";
@import url('https://use.typekit.net/vzv1rny.css');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

:root {
    --text-dark: #333;
    --text-grey: #555;
    --text-light-grey: #777;
    --text-white: #eee;
    --primary-color: #889b9d;
    --primary-color-bright: #f2f3f3;
    --primary-color-dark: #5e6566;
    --primary-color-darkest: #3d4242;
    --accent-color: #deacae;

    --font-operetta: 'operetta-32';
    --font-roboto: 'Roboto';
}

*,
*::before,
*::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

$tablet_portrait_width: 600px;
$tablet_landscape_width: 1000px;
$desktop_width: 1200px;

html {
    scroll-behavior: smooth;
    height: 100vh;
}

body {
    height: 100vh;
    font-family: var(--font-roboto), sans-serif;
    font-weight: 200;
    line-height: 1.6;
    display: flex;
    flex-direction: column;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    section {
        width: 100%;
        padding: 0;
    }
}

hr {
    border: 0;
    height: 1px;
    background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0),
        var(--primary-color),
        rgba(0, 0, 0, 0)
    );
}

.hero {
    display: flex;
    flex-direction: column;
    padding: 0 2em;
}

.hero-cta {
    display: flex;
    flex-direction: column;
    margin: 2em auto;
    text-align: center;
}

.hero-card {
    display: none;
    position: relative;
    max-width: 540px;
    margin-left: auto;
    margin-right: auto;
    animation-name: fade;
    animation-duration: 0.5s;

    &--active {
        display: block;
    }

    @media (min-width: $tablet_landscape_width) {
        max-width: 800px;
    }
}

.hero-image {
    text-align: center;
    border-radius: 0.5em;
    max-width: 100%;
    object-fit: contain;
    animation-name: fade;
    animation-duration: 1.5s;
    z-index: -10;
}

.hero-card-text {
    font-weight: 900;
    font-size: 2rem;
    line-height: 1.3em;
    color: white;
    text-shadow: 0 0 2px var(--text-grey);
    position: absolute;
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
}

.paginator {
    line-height: 0.6;
}

.hero-carousel-indicator {
    line-height: 0.6;
    font-size: 3rem;
    color: var(--text-dark);
    opacity: 0.25;

    &--active {
        opacity: 1;
    }
}

@keyframes fade {
    from {
        opacity: 0.4;
    }
    to {
        opacity: 1;
    }
}

.cta {
    margin: 1em auto;
    padding: 1em 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @media (min-width: 420px) {
        flex-direction: row;
        margin-top: 2em;
        justify-content: center;
        max-width: 800px;
    }

    &__button {
        text-decoration: none;
        text-transform: uppercase;
        font-family: var(--font-roboto);
        font-weight: bold;
        font-size: 1.1rem;
        padding: 0.25em 0.5em;
        color: white;
        border: none;
        border-radius: 1em;
        margin: 0.25em auto;
        border: 1px solid var(--accent-color);

        @media (min-width: 420px) {
            font-size: 1.1rem;
            margin: 0.25em 0.75em;
        }

        &-primary {
            background-color: var(--accent-color);
        }

        &-secondary {
            background-color: white;
            color: var(--accent-color);
        }

        &:hover {
            box-shadow: 0px 0px 8px var(--accent-color);
        }
    }

    &__link {
        color: var(--primary-color);
        text-decoration: none;
        font-weight: 700;

        &:hover {
            color: var(--accent-color);
        }
    }
}

.key-data {
    align-self: center;
    color: var(--text-dark);
    display: flex;
    flex-flow: column wrap;
    justify-content: space-evenly;

    &__item {
        flex-grow: 1;
        -ms-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
        font-size: 1.1rem;
        font-weight: 300;
        align-items: center;
        color: var(--text-grey);

        width: 100%;
        display: flex;
        flex-direction: row;
        margin-top: 1em;

        @media (min-width: 1000px) {
            max-width: 350px;
            margin-top: 1em;
            align-items: center;
        }

        h3 {
            font-weight: 500;
            color: black;
            flex-grow: 1;
        }

        img {
            max-width: 50px;
            margin-right: 1em;
        }
    }

    @media (min-width: 1000px) {
        flex-direction: row;
        max-width: 1000px;
    }
}

#services {
    background-color: var(--primary-color-bright);
    box-shadow: inset 0px 0em 3em white;
}

.services {
    padding: 2em;

    h2 {
        text-align: center;
        font-weight: 400;
        font-size: 2.2rem;
        font-weight: 700;
        color: var(--text-dark);
    }

    @media (min-width: $tablet_portrait_width) {
        margin: 0 auto;
        padding: 2em 4em;
        max-width: 1000px;
    }
}

.service-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    padding: 2em;
    margin: 2em 0;
    width: 100%;
    background-color: rgba(white, 0.8);

    &__cover {
        flex-grow: 1;
        margin: auto 0;
        max-width: 100%;
        border-radius: 0.5em;
    }

    &__content {
        flex-grow: 1;
        margin-top: 1.2em;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    q {
        display: inline-block;
    }

    &:hover {
        background-color: rgba(white, 0.5);
        cursor: pointer;
    }

    &__title {
        font-size: 1.4em;
        font-weight: 400;
        margin-bottom: 0.4em;
        color: var(--text-dark);
    }

    &__description {
        font-size: 1.2rem;
        font-weight: 300;
        color: var(--primary-color-dark);

        .quote__author {
            margin-top: 1em;
            font-style: italic;
            font-size: 0.9rem;
            text-align: left;
        }
    }

    &__link {
        margin-top: 0.4em;
        color: var(--primary-color);
        align-self: flex-end;
        text-decoration: none;
        font-weight: 500;
        padding: 0 0.5em;
        border-radius: 0.5em;
        position: relative;

        &:hover {
            background-color: var(--primary-color);
            color: white;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }

    @media (min-width: 900px) {
        flex-direction: row;
        padding: 0.5em 1em;
        background-color: var(--primary-color-bright);
        margin: 1em;
        max-width: 1000px;

        &__content {
            margin: 0 0 0 1em;
            padding: 2.2em;
        }

        &__cover {
            max-width: 420px;
        }

        &:nth-child(odd) {
            .service-card__content {
                padding: 2.2em;
                margin: 0 1em 0 0em;
            }
            flex-direction: row-reverse;
        }

        .quote__author {
            text-align: left;
        }
    }
}

.page {
    flex-grow: 1;
    padding: 0 2em;
    max-width: 1300px;
    align-self: center;
    * {
        line-height: 1.4;
        font-size: 1.2rem;
        color: var(--primary-color-dark);
    }

    &__heading {
        display: flex;
        flex-direction: column;
        padding: 0 1em;
        border-radius: 10px;
        max-width: 1000px;

        &__cover {
            flex-grow: 1;
            margin: auto 0;
            max-width: 600px;
            border-radius: 0.5em;
        }

        &__title {
            font-size: 1.8rem;
            font-weight: 400;
            margin-bottom: 0.1em;
            color: var(--text-dark);
        }

        &__subtitle {
            font-size: 1em;
            font-weight: 300;
            margin-bottom: 0.4em;
            color: var(--text-dark);
        }
    }

    &__content {
        max-width: 1240px;
        padding: 0 1em;

        p {
            -ms-hyphens: auto;
            -webkit-hyphens: auto;
            hyphens: auto;
            font-weight: 200;
            margin: 1em 0;
        }
    }

    &__services {
        margin-bottom: 2em;
        list-style: none;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        li {
            border-radius: 0.2em;
            font-weight: 500;
            width: 240px;
            padding: 1em;
            margin: 0.5em;
            background-color: var(--primary-color-bright);
        }
    }
}

.about {
    &__hero {
        padding: 1em 2em;
        display: flex;
        flex-flow: column;

        &__content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: var(--text-grey);
            margin-bottom: 1em;
        }

        &__heading {
            display: flex;
            flex-direction: column-reverse;
            justify-content: center;
            margin-bottom: 0.5em;

            @media (min-width: 460px) {
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }
        }

        &__picture {
            align-self: center;
            width: 100%;
            height: auto;
            border-radius: 0.5em;
        }

        &__cta {
            align-self: center;
            justify-self: center;
            text-align: center;
            margin: 1em;
            font-size: 1.2rem;
            font-weight: 300;
            color: var(--text-dark);
        }

        @media (min-width: $tablet_portrait_width) {
            &__content {
                flex-direction: row;
                justify-content: center;
                margin-bottom: 0;
            }

            &__heading {
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                margin-bottom: 0;
            }

            &__picture {
                max-width: 300px;
                margin-left: 2em;
            }

            &__cta {
                margin: 1em;
            }
        }
    }

    &__title {
        font-size: 1.8rem;
        font-weight: 400;
        color: var(--text-dark);
    }

    &__subtitle {
        font-size: 1rem;
        font-weight: 300;
        max-width: 24ch;
        line-height: 1.2;
        color: var(--text-grey);
    }

    &__text {
        padding: 2rem;
        max-width: 900px;
        margin-left: auto;
        margin-right: auto;
        font-size: 1.2rem;
        color: var(--text-grey);
        p {
            -ms-hyphens: auto;
            -webkit-hyphens: auto;
            hyphens: auto;
            font-weight: 300;
            margin: 1em 0;
            &:first-of-type {
                margin-top: 0;
            }
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    @media (min-width: $tablet_portrait_width) {
        &__title {
            margin-top: 1em;
            font-size: 1.7rem;
        }
        &__subtitle {
            font-size: 1.1rem;
            font-weight: 300;
        }
    }

    @media (min-width: 800px) {
        &__title {
            font-size: 2.6rem;
        }
        &__subtitle {
            font-size: 1.2rem;
        }
    }
}

.cv {
    --cv-color: var(--primary-color);
    background-color: var(--primary-color-bright);
    padding: 2rem 2rem;
    display: flex;
    flex-direction: column;
    width: 100%;

    &__title {
        font-size: 1.4rem;
        font-weight: 500;
    }

    &__item {
        display: flex;

        &__bullet {
            background-color: var(--cv-color);
            color: var(--text-white);
            display: flex;
            align-items: center;
            justify-content: center;
            align-self: center;
            font-weight: 900;
            font-size: 1.1rem;
            border-radius: 50%;
            width: 3.5rem;
            height: 3.5rem;
            position: relative;

            &::before,
            &::after {
                content: '';
                position: absolute;
                width: 3px;
                background-color: var(--cv-color);
            }

            &::before {
                bottom: 100%;
                height: 50%;
            }

            &::after {
                top: 100%;
                height: 65%;
            }
        }

        &:hover &__bullet,
        &:focus-within &__bullet {
            transform: scale(1.1);
        }

        &__content {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 77%;
            margin-left: 1em;
            min-height: 90px;
            line-height: 1.2;
            p {
                margin-top: 0.2em;
            }
        }

        &__name {
            font-size: 1.1rem;
            font-weight: 400;
        }

        &__description {
            font-size: 1rem;
        }

        &:first-of-type &__bullet::before,
        &:last-of-type &__bullet::after {
            display: none;
        }
    }

    @media (min-width: $tablet_portrait_width) {
        padding-left: 20%;
    }
}

.contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-grow: 1;

    &__logo {
        margin-top: 1em;
        width: 240px;
        -webkit-transition: -webkit-transform 500ms ease-in-out;
        transition: -webkit-transform 500ms ease-in-out;
        transition: transform 500ms ease-in-out;
        transition: transform 500ms ease-in-out,
            -webkit-transform 500ms ease-in-out;
        &:hover {
            -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
        }
    }

    &__heading {
        font-size: 2.4rem;
        font-weight: 400;
        margin-top: 1em;
        color: var(--primary-color-dark);
    }

    &__sub-heading {
        font-size: 1.4rem;
        font-weight: 300;
        margin-bottom: 1em;
    }

    &__content {
        width: 240px;
        margin-bottom: 2em;
        font-weight: 300;
    }

    &__phone {
        display: block;
        margin-top: 0.4em;
        position: relative;
        margin-top: 0.4em;
        margin-left: 1.5em;
        text-decoration: none;
        &::before {
            content: '\f879';
            font-family: 'Font Awesome 5 Free';
            font-weight: 600;
            position: absolute;
            left: -1.5em;
        }
    }
    &__email {
        display: block;
        unicode-bidi: bidi-override;
        text-align: left;
        direction: rtl;
        position: relative;
        margin-top: 0.4em;
        margin-left: 1.5em;
        text-decoration: none;

        &::before {
            content: '\f1fa';
            font-family: 'Font Awesome 5 Free';
            font-weight: 600;
            position: absolute;
            left: -1.5em;
        }
    }

    &__address {
        display: inline-block;
        width: 300px;
        margin-top: 0.4em;
        margin-bottom: 2em;
        margin-left: 1.5em;
        list-style: none;
        position: relative;

        &::before {
            content: '\f0e0';
            font-family: 'Font Awesome 5 Free';
            position: absolute;
            left: -1.5em;
        }
    }

    @media (min-width: 700px) {
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 3em;

        &__logo {
            width: 240px;
            margin-top: 0;
            margin-bottom: 0;
        }

        &__content {
            margin-top: 0;
            margin-bottom: 0;
            margin-right: 2em;
        }
    }
}
