.footer {
    background-color: var(--primary-color-darkest);
    color: var(--text-white);

    &__content {
        padding: 1.6em 2em 4em;
        flex-grow: 1;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        position: relative;
        align-items: flex-start;

        @media (min-width: 900px) {
            width: 90%;
            max-width: 1200px;
            margin: 0 auto;
        }
    }

    &__veb-logo {
        display: none;
    }

    &__left {
        flex-grow: 1;
    }

    &__right {
        align-self: flex-end;
        text-align: right;
    }

    &__title {
        font-family: var(--font-operetta);
        font-size: 2em;
        font-weight: 400;
        margin-bottom: -0.5em;
    }

    &__address {
        display: none;
    }

    &__disclaimer {
        margin-top: 0.4em;
        display: block;
        * {
            color: rgba(white, 0.4);
            font-size: 0.7rem;
        }
        ul {
            list-style: none;
        }
        li {
            display: inline;
        }
        a {
            text-decoration: none;
            color: rgba(white, 0.4);
            &:hover {
                color: rgba(white, 0.8);
            }
        }
    }

    &__email,
    &__phone {
        display: none;
    }

    &__name {
        font-size: 0.9em;
        font-weight: 200;
        color: rgba(white, 0.6);
        display: none;
    }

    &__icon {
        display: inline;
        margin-top: 0.4em;
        margin-left: 0.15em;
        margin-bottom: 3em;
        text-decoration: none;
        line-height: 0.8;
        color: rgba(white, 0.6);
        i {
            font-size: 1.6em;
        }

        &:hover {
            color: var(--text-white);
        }
    }

    @media (min-width: 700px) {
        &__veb-logo {
            opacity: 0.7;
            display: block;
            align-self: center;
            width: 4.2em;
            margin-right: 1.4em;
            &:hover {
                opacity: 0.8;
            }
        }
        &__title {
            color: var(--text-white);
            line-height: 1.2;
            margin: 0;
        }

        &__address {
            display: block;
            font-weight: 200;
            line-height: 1.4;
        }

        &__right {
            * {
                line-height: 1.8;
            }
        }

        &__email {
            display: block;
            color: var(--text-white);
            unicode-bidi: bidi-override;
            text-align: left;
            direction: rtl;
            font-weight: 200;
            i {
                font-size: 0.9em;
                margin-right: 0.5em;
                opacity: 0.6;
            }
        }

        &__phone {
            display: block;
            color: var(--text-white);
            text-align: right;
            font-weight: 200;
            line-height: 1.4;
            i {
                font-size: 0.9em;
                margin-right: 0.5em;
                opacity: 0.6;
            }
        }

        &__disclaimer {
            position: absolute;
            bottom: 1em;
            right: 2em;

            li {
                margin: 0 0.2em;
            }
        }
    }
}
